import classes from './Title.module.scss';

export function Title(props) {
  const bigClass = props.big ? classes['title--big'] : '';
  const gonClass = props.gon ? classes['title--gon'] : '';
  const faqsClass = props.faqs ? classes['title--faqs'] : '';
  const rightClass = props.right ? classes['title--right'] : '';
  const plainClass = props.plain ? classes['title--plain'] : '';
  const whiteClass = props.white ? classes['title--white'] : '';
  const killuaClass = props.killua ? classes['title--killua'] : '';
  const maroonClass = props.maroon ? classes['title--maroon'] : '';
  const centerClass = props.center ? classes['title--center'] : '';
  const invertedClass = props.inverted ? classes['title--inverted'] : '';
  const partnershipsClass = props.partnerships ? classes['title--partnerships'] : '';

  return <h2
    className={`${classes.title} ${invertedClass} ${gonClass} ${killuaClass} ${bigClass} ${rightClass} ${maroonClass} ${whiteClass} ${centerClass} ${partnershipsClass} ${plainClass} ${faqsClass}`}
    title={props.label}>
    {props.label}
  </h2>
}
