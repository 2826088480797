import { Fade, Slide } from 'react-awesome-reveal';
import { Partnerships } from '../partnerships/Partnerships';
import { Socials } from '../socials/Socials';
import { Faqs } from './../faqs/Faqs';

import classes from './Footer.module.scss';

export function Footer() {
  return (
    <footer className={`${classes.footer}`}>
      <div className={classes.footer__container}>
        <div className={`${classes.row} ${classes['row--first']}`}>
          <div className={classes.column}>
            <Fade triggerOnce>
              <Slide triggerOnce direction='left'>
                <Faqs />
              </Slide>
            </Fade>
          </div>
          <div className={`${classes.column} ${classes['column--partnerships']}`}>
            <Fade triggerOnce>
              <Slide triggerOnce direction='right'>
                <Partnerships />
              </Slide>
            </Fade>
          </div>
        </div>

        <div className={`${classes.row} ${classes['row--second']}`}>
          <div className={classes.column}>
            <Fade triggerOnce>
              <Socials />
            </Fade>
          </div>
        </div>
      </div>

      <div className={classes.copyright}>
        <img className={classes.copyright__logo} src="./logo192.png" alt="Shibaments logo" />
        <p className={classes.copyright__content}>Copyright © 2023</p>
      </div>
    </footer>
  );
}