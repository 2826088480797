import { useLocation, useRoutes } from 'react-router-dom';
import * as React from "react";
import { motion, AnimatePresence } from 'framer-motion';
import Home from '../../pages/Home/Home';
import Intro from '../../pages/Intro/Intro';

function App() {

  const element = useRoutes([
    {
      path: "/",
      element: (
        <Intro />
      )
    },
    {
      path: "home",
      element: (
        <Home />
      )
    }
  ]);
  const location = useLocation();

  if (!element) return null;

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={location.pathname}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        {React.cloneElement(element, { key: location.pathname })}
      </motion.div>

    </AnimatePresence>
  );
}

export default App;
