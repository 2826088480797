import classes from './Intro.module.scss';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useEffect, useRef } from 'react';

function Intro() {
  const navigate = useNavigate();
  const timeoutRef = useRef(null);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      navigate('home');
    }, 3000);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [navigate]);

  const handleClick = () => {
    navigate('home');
  };

  return (
    <>
      <div className={classes.wrapper}></div>
      <div onClick={handleClick} className={classes.center}>
        <div className={classes.pluse}>
          <motion.img
            className={classes.logo}
            src='./logo192.png'
            alt='Shibaments Logo'
            whileTap={{ scale: 0.8 }}
          />
        </div>
      </div>
    </>
  );
}

export default Intro;
