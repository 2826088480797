import React from "react";
import { Header } from "../../components/header/Header";
import { Footer } from "../../components/footer/Footer";
import { About } from "../../components/about/About";
import { Preview } from "../../components/preview/Preview";
import { Roadmap } from "../../components/roadmap/Roadmap";
import { Ecosystem } from "../../components/ecosystem/Ecosystem";

function Home() {
  return (
    <>
      <div id="container">
        <Header />
        <About />
        <Ecosystem />
        <Preview />
        {/* <Tokenomics /> */}
        <Roadmap />
        <Footer />
      </div>
    </>
  );
}

export default Home;
