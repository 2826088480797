import { Title } from '../title/Title';
import classes from './Faqs.module.scss';

export function Faqs() {
  return (
    <div className={classes.faqs}>
      <div className={classes.faqs__title}>
        <Title label="FAQs" white={true} faqs={true} plain={true} />
      </div>

      <div className={classes.faqs__content}>
        <div className={classes.faq}>
          <div className={classes.faq__question}>
            <p className={classes.question}>Q:  What are the Shibaments? </p>
          </div>
          <div className={classes.faq__answer}>
            <p className={classes.answer}>The Shibaments are unique elemental creatures that live in the blockchain. Everytime you mint one, a brand new Elemental Shiba with unique traits and power stat is created.</p>
          </div>
        </div>

        <div className={classes.faq}>
          <div className={classes.faq__question}>
            <p className={classes.question}>What is ShibaCore ?</p>
          </div>
          <div className={classes.faq__answer}>
            <p className={classes.answer}>An interface that will allow other projects to fit their own games and NFT collections into a common standard. Any other project on the Solana network will be able to integrate their own tokens or NFTs with Shibaments games through following the ShibaCore standard.</p>
          </div>
        </div>

        <div className={classes.faq}>
          <div className={classes.faq__question}>
            <p className={classes.question}>What Is ShiBattle ? </p>
          </div>
          <div className={classes.faq__answer}>
            <p className={classes.answer}>ShiBattle is the 1st game in the Shibaments ecosystem under the form of an arena card game.</p>
          </div>
        </div>
      </div>
    </div>
  );
}