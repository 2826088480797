import { Fade, Slide } from "react-awesome-reveal";

import { Title } from "../title/Title";
import { Button } from "../button/Button";

import classes from "./About.module.scss";

export function About() {
  const onPlay = () => {
    window.open("https://beta.Shibaments.com/", "_blank");
  };

  const onGitbook = () => {
    window.open("https://docs.Shibaments.com/", "_blank");
  };

  return (
    <div id="about-section" className={classes.about}>
      <div className={classes.about__left}></div>

      <div className={classes.about__content}>
        <div>
          <Fade triggerOnce>
            <div className={classes.about__card}>
              {/* <Fade delay={100} triggerOnce>
								<Slide delay={100} triggerOnce direction="up">
									<div className={classes.about__title}>
										<Title label="Shibaments" big={true} />
									</div>
								</Slide>
							</Fade> */}

              <Fade delay={300} triggerOnce>
                <Slide delay={300} direction="up" triggerOnce>
                    <p className={classes.about__description}>
                      &quot;&quot;Choose your path, forge your destiny.&quot;&quot;
                    </p>
                </Slide>
              </Fade>

              {/* <Fade delay={500} triggerOnce>
								<Slide delay={500} direction="up" triggerOnce>
									<div className={classes.about__buttons}>
										<div className={classes.about__button} onClick={onPlay}>
											<Button label="Play Game"  big={true}></Button>
										</div>

										<div className={classes.about__button} onClick={onGitbook}>
											<Button
												label="Gitbook"
												secondary={true}
												big={true}
											></Button>
										</div>
									</div>
								</Slide>
							</Fade> */}
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}
