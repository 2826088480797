import { Button } from "../button/Button";
import { Playbutton } from "../playbutton/Playbutton";

import classes from "./Header.module.scss";

export function Header() {
  const onScroll = (section) => {
    const element = document.getElementById(section);
    element?.scrollIntoView({ behavior: "smooth" });
  };

  const onPlay = () => {
    window.open(
      "https://t.me/ShibamentsBattleBot/",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const onTokenomics = () => {
    window.open(
      "https://shibattle-docs.readthedocs.io/",
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    <header className={classes.header}>
      <nav className={classes.content}>
        <div className={classes.left}>
          <img
            className={classes.logo}
            src="./logo192.png"
            alt="Shibaments logo"
          />

          <ul className={classes["menu-items"]}>
            <li
              onClick={() => onScroll("about-section")}
              className={`${classes["menu-item"]} ${classes["menu-item--desktop"]}`}
            >
              About
            </li>
            <li
              onClick={() => onScroll("ecosystem-section")}
              className={`${classes["menu-item"]} ${classes["menu-item--desktop"]}`}
            >
              Ecosystem
            </li>
            <li
              onClick={() => onScroll("preview-section")}
              className={`${classes["menu-item"]} ${classes["menu-item--desktop"]}`}
            >
              NFT Preview
            </li>

            <li
              onClick={() => onScroll("roadmap-section")}
              className={`${classes["menu-item"]} ${classes["menu-item--desktop"]}`}
            >
              Roadmap
            </li>

            <li
              onClick={() => onTokenomics()}
              className={`${classes["menu-item"]} ${classes["menu-item--desktop"]}`}
            >
              Tokenomics
            </li>
          </ul>
        </div>
        <div className={classes.right}>
          <ul className={classes["menu-items"]}>
            <li
              onClick={() => onPlay()}
              className={`${classes["menu-item"]} ${classes["menu-item--button"]}`}
            >
              <Button header={true} label="Play Now" />
            </li>
              <Playbutton />
          </ul>
        </div>
      </nav>
    </header>
  );
}
