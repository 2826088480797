import { Fade, Slide } from 'react-awesome-reveal';
import { Title } from '../title/Title';
import classes from './Roadmap.module.scss';

export function Roadmap() {
  return (
    <div id='roadmap-section' className={`${classes.roadmap}`}>
      <div className={classes.roadmap__wrapper}>
        <div className={classes.roadmap__title}>
          <Fade triggerOnce>
            <Slide triggerOnce direction='up'>
              <Title label="Roadmap" plain={true} gon={true} />
            </Slide>
          </Fade>
        </div>

        <div className={classes.phases}>
          <Fade triggerOnce>
            <Slide triggerOnce direction='up'>
              <div className={`${classes.phase}`}>
                <img className={classes.phase__animation} src="./animations/foundation/animation.gif" alt="Foundation animation" />
                <div className={classes.phase__content}>
                  <div className={classes.phase__title}>1. Foundation</div>
                  <div className={classes.phase__detail}>
                    <ul className={classes.phase__list}>
                      <li className={classes.phase__item}>Shibaments NFTs design</li>
                      <li className={classes.phase__item}>ShiBattle (Shibaments 1st P2E game) development</li>
                      <li className={classes.phase__item}>Website launch</li>
                      <li className={classes.phase__item}>Whitepaper release</li>
                    </ul>
                  </div>
                </div>
              </div>
            </Slide>
          </Fade>

          <Fade triggerOnce>
            <Slide triggerOnce direction='up'>
              <div className={`${classes.phase} `}>
                <img className={classes.phase__animation} src="./animations/prelaunch/animation.gif" alt="Pre launch animation" />
                <div className={classes.phase__content}>
                  <div className={classes.phase__title}>2. Pre Launch</div>
                  <div className={classes.phase__detail}>
                    <ul className={classes.phase__list}>
                      <li className={classes.phase__item}>1st game (ShiBattle) Beta testing</li>
                      <li className={classes.phase__item}>Community building</li>
                      <li className={classes.phase__item}>Shibaments genesis NFTs release</li>
                      <li className={classes.phase__item}>Security Audit</li>
                    </ul>
                  </div>
                </div>
              </div>
            </Slide>
          </Fade>

          <Fade triggerOnce>
            <Slide triggerOnce direction='up'>
              <div className={`${classes.phase}`}>
                <img className={classes.phase__animation} src="./animations/postlaunch/animation.gif" alt="Post launch animation" />
                <div className={classes.phase__content}>
                  <div className={classes.phase__title}>3. Post Launch</div>
                  <div className={classes.phase__detail}>
                    <ul className={classes.phase__list}>
                      <li className={classes.phase__item}>ShiBattle full release</li>
                      <li className={classes.phase__item}>Post launch marketing</li>
                      <li className={classes.phase__item}>Shibaments exclusive NFT marketplace release</li>
                      <li className={classes.phase__item}>ShibaCore release</li>
                    </ul>
                  </div>
                </div>
              </div>
            </Slide>
          </Fade>

          <Fade triggerOnce>
            <Slide triggerOnce direction='up'>
              <div className={`${classes.phase}`}>
                <img className={classes.phase__animation} src="./animations/mainstream/animation.gif" alt="Mainstream animation" />
                <div className={classes.phase__content}>
                  <div className={classes.phase__title}>4. Going Mainstream</div>
                  <div className={classes.phase__detail}>
                    <ul className={classes.phase__list}>
                      <li className={classes.phase__item}>Shibaments Launchpad release</li>
                      <li className={classes.phase__item}>Partnerships</li>
                      <li className={classes.phase__item}>2nd Game release</li>
                      <li className={classes.phase__item}>More to be announced</li>
                    </ul>
                  </div>
                </div>
              </div>
            </Slide>
          </Fade>
        </div>
      </div>

      <div className={classes.roadmap__fade}></div>
    </div>
  );
}
