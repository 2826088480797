import classes from './Rainbow.module.scss';

export function Rainbow(props) {
  const content = props.children ?? '';
  const html = content.split('').map(character => <span className={classes.rainbow__character}>{character}</span>)

  return (
    <div className={`${classes.rainbow} ${props.hovered ? classes['rainbow--hovered'] : ''}`}>
      {html}
    </div>
  )
}