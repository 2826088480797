import { Title } from '../title/Title';
import classes from './Socials.module.scss';

export function Socials() {
  return (
    <div className={classes.socials}>
      <div className={classes.socials__title}>
        <Title label="Socials" plain={true} center={true} white={true} />
      </div>

      <div className={classes.socials__content}>
        <ul className={classes.socials__list}>
          <li className={`${classes.socials__item} ${classes['socials__item--discord']}`}>
            <a rel="noopener noreferrer" href='https://discord.gg/R8DVQ4XTwh' target="_blank">
              <img className={classes.socials__logo} src="./icons/discord.svg" alt="Discord icon"></img>
            </a>
          </li>

          <li className={`${classes.socials__item} ${classes['socials__item--telegram']}`}>
            <a rel="noopener noreferrer" href='https://t.me/shibaments' target="_blank">
              <img className={classes.socials__logo} src="./icons/telegram.svg" alt="Telegram icon"></img>
            </a>
          </li>

          <li className={`${classes.socials__item} ${classes['socials__item--twitter']}`}>
            <a rel="noopener noreferrer" href='https://twitter.com/shibaments' target="_blank">
              <img className={classes.socials__logo} src="./icons/twitter.svg" alt="Twitter icon"></img>
            </a>
          </li>

          <li className={`${classes.socials__item} ${classes['socials__item--gitbook']}`}>
            <a rel="noopener noreferrer" href='https://shibattle-docs.readthedocs.io/#' target="_blank">
              <img  className={classes.socials__logo} src="./icons/gitbook.svg" alt="Gitbook icon"></img>
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}