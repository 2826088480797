import { useState, useEffect } from 'react';
import classes from './Playbutton.module.scss';

export function Playbutton() {
  const [isChecked, setIsChecked] = useState(false);
  const isCheckedClass = isChecked ? classes.pause : classes.play;

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    const audio = new Audio('./audio/shibaments-theme.mp3');

    if (isChecked) {
      audio.play();
    } else {
      audio.pause();
    }

    return () => {
      // Cleanup function to stop the audio when the component unmounts
      audio.pause();
      audio.currentTime = 0;
    };
  }, [isChecked]);

  return (
    <div className={classes.container}>
      <label htmlFor="checkbox">
        <div className={`${classes.play_pause_icon} ${isCheckedClass}`}></div>
      </label>
      <input className="checkbox" id="checkbox" type="checkbox" onChange={handleChange} />
    </div>
  );
}
