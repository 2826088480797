import { useState } from 'react';
import { Rainbow } from '../rainbow/Rainbow';
import classes from './Button.module.scss';

export function Button(props) {
  const secondaryClass = props.secondary ? classes['button--secondary'] : '';
  const loliClass = props.loli ? classes['button--loli'] : '';
  const bigClass = props.big ? classes['button--big'] : '';
  const headerClass = props.header ? classes['button--header'] : '';

  const [hovered, setHoevered] = useState(false);

  const onHover = () => {
    setHoevered(true);
  }

  const onBlur = () => {
    setHoevered(false);
  }

  return (
    <button onMouseEnter={onHover} onMouseLeave={onBlur} className={`${classes.button} ${loliClass} ${secondaryClass} ${bigClass} ${headerClass}`}>
      <span className={classes.button__content}>
        <Rainbow hovered={hovered}>{props.label}</Rainbow>
      </span>
    </button>
  )
}
