import { Title } from '../title/Title';
import { Button } from '../button/Button';
import { Fade, Slide } from 'react-awesome-reveal';

import classes from './Ecosystem.module.scss';

export function Ecosystem() {
  const onMoreInfo = () => {
    window.open('https://shibattle-docs.readthedocs.io/', '_blank');
  }

  return (
    <div id='ecosystem-section' className={classes.ecosystem}>
      <div className={classes.ecosystem__content}>
        <div className={classes.ecosystem__title}>
          <Fade triggerOnce>
            <Slide direction="up" triggerOnce>
              <Title label="Ecosystem" inverted={true} />
            </Slide>
          </Fade>
        </div>

        <div className={classes.ecosystem__cards}>
          <div className={classes.ecosystem__top}>
            <Fade triggerOnce delay={200}>
              <Slide direction="up" triggerOnce delay={200}>
                <p>
                  Welcome to Shibaments ! engage in Battles, Boss raids and much more...
                </p>
              </Slide>
            </Fade>

            <Fade triggerOnce delay={400}>
              <Slide direction="up" triggerOnce delay={400}>
                <p>
                  Discover a GameFi ecosystem featuring the cute and powerful creatures known as the Shibaments where you can collect, trade, play games, and earn rewards!
                </p>
              </Slide>
            </Fade>


          </div>

          <div className={classes.ecosystem__bottom}>
            {/* <Fade triggerOnce className={classes.ecosystem__cardy}> */}

            <Fade triggerOnce>
              <Slide triggerOnce direction='right'>
                <div className={classes.ecosystem__card}>
                  <div className={classes['ecosystem__card-title']}>
                    <h4>GameFi</h4>
                  </div>
                  <div className={classes['ecosystem__card-content']}>
                    <p>
                      With ShiBattle, players can put their Shibaments NFTs to the test and earn daily rewards.
                    </p>
                    <br />
                    {/* <a className={`${classes.link} link`} rel="noopener noreferrer" target="_blank" href="#ecosystem-section">More info</a> */}
                  </div>

                  {/*   <img className={`${classes.ecosystem__illustration} ${classes['ecosystem__illustration--p2e']}`} alt="P2E illustration" src="./illustrations/dfe.png" /> */}                </div>
              </Slide>
            </Fade>

            {/* <Fade triggerOnce className={classes.ecosystem__cardy}> */}

            <Fade triggerOnce>
              <Slide triggerOnce direction='right'>
                <div className={classes.ecosystem__card}>
                  <div className={classes['ecosystem__card-title']}>
                    <h4>Art</h4>
                  </div>
                  <div className={classes['ecosystem__card-content']}>
                    <p>
                      Discover a collection of unique, hand drawn creatures with multiple utilities !
                    </p>
                    <br />
                    {/* <a className={`${classes.link} link`} rel="noopener noreferrer" target="_blank" href="#ecosystem-section">More info</a> */}
                  </div>

                  {/* <img className={`${classes.ecosystem__illustration} ${classes['ecosystem__illustration--nfts']}`} alt="NFTs illustration" src="./illustrations/x.png" /> */}                </div>
              </Slide>
            </Fade>

            {/* <Fade triggerOnce className={classes.ecosystem__cardy}> */}

            <Fade triggerOnce>
              <Slide triggerOnce direction='right'>
                <div className={classes.ecosystem__card}>
                  <div className={classes['ecosystem__card-title']}>
                    <h4>Utilities</h4>
                  </div>
                  <div className={classes['ecosystem__card-content']}>
                    <p>
                      A Launchpad that will allow new projects to be integrated in our games. And much more to come..
                    </p>
                    <br />
                    {/* <a className={`${classes.link} link`} rel="noopener noreferrer" target="_blank" href="#ecosystem-section">More info</a> */}
                  </div>

                  {/* <img className={`${classes.ecosystem__illustration} ${classes['ecosystem__illustration--staking']}`} alt="Staking illustration" src="./illustrations/d.png" /> */}
                </div>
              </Slide>
            </Fade>
          </div>

          <div className={classes.ecosystem__buttons}>
            <Fade triggerOnce delay={600}>
              <Slide direction="up" triggerOnce delay={600}>
                <span onClick={onMoreInfo}>
                  <Button label="More info"></Button>
                </span>
              </Slide>
            </Fade>
          </div>
        </div>
      </div>

      <div className={classes.ecosystem__fade}></div>
    </div>
  );
}
