import { Title } from '../title/Title';

import Slider from "react-slick";
import { Fade, Slide } from 'react-awesome-reveal';

import classes from './Preview.module.scss';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function Preview() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: false,

    responsive: [
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 790,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 530,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div id='preview-section' className={classes.preview}>
      <div className={classes.preview__wrapper}>
        <div className={classes.preview__title}>
          <Fade triggerOnce>
            <Slide triggerOnce direction="up">
              <Title label="NFT Collection" killua={true} plain={true} />
            </Slide>
          </Fade>
        </div>
        <Fade triggerOnce>
          <Slide triggerOnce direction="up">
            <p className={classes.preview__text}>
              Shibaments are unique elemental creatures that live within the blockchain. <br /> Every time you mint an NFT, a brand new Shibament with unique traits and power stat is created.
            </p>
          </Slide>
        </Fade>
      </div>

      <Fade triggerOnce>
        <Slide triggerOnce direction='up'>
          <Slider className={classes.preview__cards} {...settings}>
            <div className={classes.preview__card}><img src="./cards/wind.png" alt="Wind Shibament Card" /></div>
            <div className={classes.preview__card}><img src="./cards/fire.png" alt="Fire Shibament Card" /></div>
            <div className={classes.preview__card}><img src="./cards/water.png" alt="Water Shibament Card" /></div>
            <div className={classes.preview__card}><img src="./cards/plant.png" alt="Plant Shibament Card" /></div>
            <div className={classes.preview__card}><img src="./cards/earth.png" alt="Earth Shibament Card" /></div>
            <div className={classes.preview__card}><img src="./cards/ice.png" alt="Ice Shibament Card" /></div>
            <div className={classes.preview__card}><img src="./cards/lightning.png" alt="Lightning Shibament Card" /></div>
          </Slider>
        </Slide>
      </Fade>

      <div className={classes.preview__fade}></div>
    </div>
  );
}
