import { Title } from './../title/Title';
import classes from './Pertnerships.module.scss';

export function Partnerships() {
  return (
    <div className={classes.partnerships}>
      <div className={classes.partnerships__title}>
        <Title label="Partnerships" plain={true} partnerships={true} white={true} />
      </div>

      <div className={classes.partnerships__content}>
        <div className={classes.row}>
          <h4 className={classes['sub-title']}>Powered by</h4>
          <img className={`${classes.logo} ${classes['logo--opbnb']}`} src="./icons/sol-logo.png" alt="SOL logo"></img>
        </div>
        {/* <div className={classes.row}>
          <h4 className={classes['sub-title']}>Official partners</h4>
        </div> */}
        {/* <div className={classes.row}>
          <h4 className={classes['sub-title']}>Audits</h4>
          <h5>Coming Soon</h5>
        </div> */}
      </div>
    </div>
  );
}
